import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { userAppUrl } from '../Helpers/Constants';
import Copyright from '../Helpers/Copyright';
import Header from '../Helpers/Header';
import DownloadButtonsGroup from '../Helpers/DownloadButtonsGroup';
import UpdateLocation from '../Helpers/UpdateLocation';
import IUser from './IUser';
import { getUser } from './UserService';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const User = () => {
    let { entityId } = useParams<{ entityId: string }>();
    const [searchParams] = useSearchParams();
    const [userData, setUserData] = useState<IUser>();
    const [isInErrorState, setIsInErrorState] = useState<boolean>(false);

    useEffect(() => {
        const loadData = async (id: string) => {
            try {
                const res: IUser = await getUser(id);
                return res;
            } catch (e) {
                console.error(e);
                setIsInErrorState(true);
            }
        };

        if (entityId) {
            loadData(entityId).then((res) => {
                if (res) {
                    setUserData(res);
                }
            });
        }
    }, [entityId]);

    return (
        <>
        <Container maxWidth={false} sx={{ maxWidth: 400 }}>
            <Header/>
            {!isInErrorState && userData &&
            <Card elevation={0} sx={{ mt: 2 }}>
                <CardContent sx={{ p: 0 }}>
                    <Box sx={{ p: 2, border: '4px solid #D7E2EE', borderRadius: '50%', width: 'fit-content', m: '0 auto' }}>
                        <Avatar
                            alt={userData?.name}
                            src={userData?.photoUrl}
                            sx={{ width: 160, height: 160 }}
                        />
                    </Box>
                    {searchParams.get('hideButtons') !== 'true' &&
                        <DownloadButtonsGroup openInAppUrl={`${userAppUrl}${entityId}`}/>
                    }
                    <Typography gutterBottom variant='h2' sx={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 500 }} marginTop={3}>
                        {userData?.name}
                    </Typography>
                    <Typography sx={{ whiteSpace: "pre-wrap" }} variant='body1'>
                        {userData?.bio}
                    </Typography>
                </CardContent>
            </Card>}
            <Copyright />
        </Container>
        <UpdateLocation title={userData?.name}/></>
    );
}

export default User;
