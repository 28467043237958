import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { mapObjAppUrl, modelScaleValue, environmentMapPath } from '../Helpers/Constants';
import Copyright from '../Helpers/Copyright';
import Header from '../Helpers/Header';
import DownloadButtonsGroup from '../Helpers/DownloadButtonsGroup';
import UpdateLocation from '../Helpers/UpdateLocation';
import IObject from './IObject';
import { getObject } from './ObjectService';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import '@google/model-viewer';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'model-viewer': MyElementAttributes
        }
        interface MyElementAttributes {
            'src': string,
            'poster': string,
            'environment-image': string,
            'children'?: never[],
            'alt'?: string,
            'ar'?: boolean,
            'disable-zoom'?: boolean,
            'camera-controls'?: boolean,
            'camera-orbit'?: string,
            'touch-action'?: string,
        }
    }
}

const Object = () => {
    let { entityId } = useParams<{ entityId: string }>();
    const [searchParams] = useSearchParams();
    const [objectData, setObjectData] = useState<IObject>();
    const [isInErrorState, setIsInErrorState] = useState<boolean>(false);

    useEffect(() => {
        const loadData = async (id: string) => {
            try {
                const res: IObject = await getObject(id);
                return res;
            } catch (e) {
                console.error(e);
                setIsInErrorState(true);
            }
        };

        if (entityId) {
            loadData(entityId).then((res) => {
                if (res) {
                    setObjectData(res);
                }
            });
        }
    }, [entityId]);

    return (
        <>
        <Container maxWidth={false} sx={{ maxWidth: 400 }}>
            <Header/>
            {!isInErrorState && objectData &&
            <Card elevation={0} sx={{ mt: 2 }}>
                <CardContent sx={{ p: 0 }}>
                    <Box border='1px solid #E2E2E2' borderRadius={8} padding={4}>
                    <model-viewer
                        alt={objectData?.displayName}
                        src={objectData?.modelUrl}
                        ar
                        environment-image={environmentMapPath}
                        poster={objectData?.imageUrl}
                        camera-controls
                        camera-orbit={`10deg 90deg ${modelScaleValue}m`}
                        touch-action='pan-y'>
                    </model-viewer>
                    </Box>
                    {searchParams.get('hideButtons') !== 'true' &&
                        <DownloadButtonsGroup openInAppUrl={`${mapObjAppUrl}${entityId}`}/>
                    }
                    <Typography gutterBottom variant='h2' sx={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 500 }} marginTop={3}>
                        {objectData?.displayName}
                    </Typography>
                    <Typography variant='body1' component='div'>
                        <ReactMarkdown children={objectData?.details} skipHtml={true} />
                    </Typography>

                    <Box sx={{mt: 2}}>
                        { objectData?.link && <Link href={objectData.link.url}>{objectData.link.title}</Link> }
                    </Box>
                </CardContent>
            </Card>}
            <Copyright />
        </Container>
        <UpdateLocation title={objectData?.displayName}/></>
    );
}

export default Object;
