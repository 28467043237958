import { useEffect } from "react";

const UpdateLocation = (props: {title: string}) => {
    const { title } = props;
    useEffect(() => {
        if (title && window.location.href.indexOf('#') === -1) {
            // Encode value and replace %xx with _
            let encodedTitle = encodeURIComponent(title.replace(/ /g, "_")).replace(/%../g, "_");

            // Remove duplicated _ and set fragment
            window.location.href += `#___${encodedTitle.replace(/_{2,}/g, "_")}`;
        }
    }, [title]);

    return <></>
};

export default UpdateLocation;
