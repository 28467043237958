import * as React from 'react';
import { publicWebsiteUrl, defaultUrl } from './Constants';
import { Box, Link, Stack } from '@mui/material';
import { Helmet } from "react-helmet";

const Header = () => {
    return (
        <>
        <Helmet>
            <meta name="theme-color" content="#FFFFFF" />
        </Helmet>
        <Stack spacing={2} direction='column' textAlign='center' marginTop={4}>
            <Box
                mt='2rem'
                component="img"
                sx={{
                    height: 50,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                alt='Cyber Trophy logo'
                src={`${publicWebsiteUrl}/logoCS.svg`} />
            <Link underline='hover' href={defaultUrl}>Explore the world with CyberTrophy</Link>
        </Stack>
        </>
    );
}

export default Header;
