import { API_ENDPOINT } from '../Helpers/Constants';
import IObject from './IObject';

export const getObject = async (objectId: string): Promise<IObject> => {
    const data = (window as any).cyberTrophy;
    if (data) {
        return data;
    }

    const url = `${API_ENDPOINT}/api/MapObjects/Get?id=${objectId}`;
    const response = await fetch(url);

    return await response.json();
};

