import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { collectionAppUrl } from '../Helpers/Constants';
import Copyright from '../Helpers/Copyright';
import Header from '../Helpers/Header';
import DownloadButtonsGroup from '../Helpers/DownloadButtonsGroup';
import UpdateLocation from '../Helpers/UpdateLocation';
import ICollection from './ICollection';
import { getCollection } from './CollectionService';
import ReactMarkdown from 'react-markdown';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Collection = () => {
    let { entityId } = useParams<{ entityId: string }>();
    const [searchParams] = useSearchParams();
    const [collectionData, setCollectionData] = useState<ICollection>();
    const [isInErrorState, setIsInErrorState] = useState<boolean>(false);

    useEffect(() => {
        const loadData = async (id: string) => {
            try {
                const res: ICollection = await getCollection(id);
                return res;
            } catch (e) {
                console.error(e);
                setIsInErrorState(true);
            }
        };

        if (entityId) {
            loadData(entityId).then((res) => {
                if (res) {
                    setCollectionData(res);
                }
            });
        }
    }, [entityId]);

    return (
        <>
        <Container maxWidth={false} sx={{ maxWidth: 400 }}>
            <Header/>
            {!isInErrorState && collectionData &&
            <Card elevation={0} sx={{ mt: 2 }}>
                <CardContent sx={{ p: 0 }}>
                    <Box>
                        <CardMedia
                            component='img'
                            image={collectionData?.imageUrl}
                            alt={collectionData?.displayName}
                            sx={{ width: 350, height: 350, m: '0 auto', borderRadius: 8 }}
                        />
                    </Box>
                    {searchParams.get('hideButtons') !== 'true' &&
                        <DownloadButtonsGroup openInAppUrl={`${collectionAppUrl}${entityId}`}/>
                    }
                    <Typography gutterBottom variant='h2' sx={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 500 }} marginTop={3}>
                        {collectionData?.displayName}
                    </Typography>
                    <Typography variant='body1' component='div'>
                        <ReactMarkdown children={collectionData?.details} skipHtml={true} />
                    </Typography>
                    <Box>
                        { collectionData?.link && <Link href={collectionData.link.url}>{collectionData.link.title}</Link> }
                    </Box>
                </CardContent>
            </Card>}
            <Copyright />
        </Container>
        <UpdateLocation title={collectionData?.displayName}/></>
    );
}

export default Collection;
