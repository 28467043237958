import IconButton from "@mui/material/IconButton";
import { downloadUrl, appStoreUrl, googlePlayUrl } from './Constants';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DownloadIOS from "./Svg/DownloadIOS";
import DownloadAndroid from "./Svg/DownloadAndroid";
import DownloadDesktop from "./Svg/DownloadDesktop";
import { UAParser } from 'my-ua-parser';

const DownloadButton = (props) => (
    <IconButton
        href={props.href}
        target='_blank'
        size='medium'
        sx={[
            {
                padding: 0,
                borderRadius: '14px',
                backgroundColor: 'black',
            },
            {
                '&:hover': {
                    backgroundColor: 'black',
                },
            }
        ]}
    >
      {props.children}
    </IconButton>
);

const DownloadButtonsGroup = (props: {openInAppUrl: string}) => {
    const { openInAppUrl } = props;
    const uap = new UAParser();

    return (
        <Stack sx={{ mt: 3 }} direction='row' justifyContent='center' spacing={2}>
        <Button variant='contained' href={openInAppUrl} size='large' style={{ fontSize: '17px', textTransform: 'none', borderRadius: '14px', height: '46px' }}>Open in App</Button>
        {
            uap.getDevice().type === 'mobile' || uap.getDevice().type === 'tablet'
                ? uap.getOS().name === 'iOS'
                    ? (<DownloadButton href={appStoreUrl}>
                        <DownloadIOS/>
                    </DownloadButton>)
                    : (<DownloadButton href={googlePlayUrl}>
                        <DownloadAndroid/>
                    </DownloadButton>)
                : (<DownloadButton href={downloadUrl}>
                    <DownloadDesktop/>
                </DownloadButton>)
        }
        </Stack>
    );
}

export default DownloadButtonsGroup;
