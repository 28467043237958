import * as React from 'react';
import Typography from '@mui/material/Typography';
import { defaultUrl } from './Constants';
import Link from '@mui/material/Link';

const Copyright = () => {
    return (
    <Typography
        variant="body2"
        align="center"
        my={2}
        sx={{
            whiteSpace: 'pre-line',
            color: 'text.secondary',
        }}
    >
        {`© 2022-${new Date().getFullYear()} Continuum.Social Inc`}
        {'\nExplore the world and earn digital rewards!\n'}
        <Link sx={{ mb: 2 }} href={defaultUrl}>www.continuum.social</Link>
    </Typography>
    );
}

export default Copyright;