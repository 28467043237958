import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
} from "react-router-dom";
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Collection from "./Collection/Collection";
import Object from "./Object/Object";
import User from "./User/User";
import { defaultUrl } from "./Helpers/Constants";
import { createTheme, ThemeProvider } from '@mui/material';
import Redirect from './Helpers/Redirect';

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const Main = () => {
  let { entityId } = useParams<{ entityId: string }>();

  if (entityId.startsWith('obj-')) {
    return <Object />
  }

  if (entityId.startsWith('col-')) {
    return <Collection />
  }

  if (entityId.startsWith('usr-')) {
    return <User />
  }

  return <Redirect url={defaultUrl} />
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Redirect url={defaultUrl} />,
    errorElement: <Redirect url={defaultUrl} />,
  },
  {
    path: ":entityId",
    element: <Main />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <RouterProvider router={router} />
  </ThemeProvider>
);
