import { API_ENDPOINT } from '../Helpers/Constants';
import IUser from './IUser';

export const getUser = async (objectId: string): Promise<IUser> => {
    const data = (window as any).cyberTrophy;
    if (data) {
        return data;
    }

    const url = `${API_ENDPOINT}/api/Profiles/Get?id=${objectId}`;
    const response = await fetch(url);

    return await response.json();
};

