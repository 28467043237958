export const publicWebsiteUrl = 'https://public.cybertrophy.social';

export const defaultUrl = 'https://www.continuum.social/';

export const downloadUrl = 'https://www.continuum.social/cybertrophy';
export const appStoreUrl = 'https://apps.apple.com/app/cybertrophy/id1639730053';
export const googlePlayUrl = 'https://play.google.com/store/apps/details?id=social.continuum.artravel';

export const modelScaleValue = 2.2;
export const environmentMapPath = `${publicWebsiteUrl}/environment-map.hdr`;
export const mapObjAppUrl = 'cybertrophy://map/object?routingParam=';
export const collectionAppUrl = 'cybertrophy://map/collection?routingParam=';
export const userAppUrl = 'cybertrophy://map/user?routingParam=';
export const emptyUserImage = `${publicWebsiteUrl}/user-profile.png`;

export const API_ENDPOINT = 'https://api.cybertrophy.social';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT
    : 'Development';
